<template>
<div class='tableColumns' :class='{ tilt }'>
	<div 
		v-for='(column, index) in columns'
		:class='{
			selectable: column.sort,
			selected: sortColumn === column.sort,
			desc: sortDirection === "desc"
		}'
		:key='index'
		:style='`flex: ${column.flex}; flex-basis: ${column.flexBasis || "\"auto\"" };`'
		@click='() => toggleColumn(column.sort)'
	><div>
		<span>
			{{column.label}}
			<template v-if='column.sort && column.sort.includes(",") && sortColumn'>({{sortColumn[0].toUpperCase() + sortColumn.slice(1)}})</template>
		</span>
		<svg v-if='column.sort && column.sort.includes(sortColumn)' class='caret'><use xlink:href='#caret' /></svg>
		<svg v-if='column.detail && sortColumn !== column.sort' class='detail' @click.stop='() => showDetail(column.detail.title, column.detail.message)'>
			<use xlink:href='#detail' />
		</svg>
	</div></div>
</div>
</template>

<script>
import { get } from 'lodash'
import { smallModalMixin } from '@/components/common/mixins/modal'
import AlertModal from '@/components/common/modals/Alert'

export default {
	name: 'TableColumns',
	mixins: [smallModalMixin],
	props: ['module', 'columns', 'tilt'],
	computed: {
		sortColumn() {
			return this.module ? get(this.$store.state, this.module.split('/').join('.') + '.sortColumn') : false
		},
		sortDirection() {
			return this.module ? get(this.$store.state, this.module.split('/').join('.') + '.sortDirection') : false
		}
	},
	methods: {
		toggleColumn(column) {
			let col = ''
			if (column) {
				if (column.includes(',')) {
				//	const [a, b] = column.split(',')
					const arr = column.split(',')
					const index = arr.findIndex(v=>v===this.sortColumn)
					if (this.sortDirection === 'desc') {
						if (index+1===arr.length) col = arr[index]
						else col = arr[index+1]
					} else if (index!==-1) {
						col = arr[index]
					} else {
						col = arr[0]
					}
					//else if (index)
/*
					if (this.sortDirection === 'desc') {
						col = arr[arr.findIndex(v=>v===this.sortColumn)+1]
					} else {
						col = arr[0]
					}
					/*
					if (
						this.sortColumn === a && this.sortDirection === 'desc' ||
						this.sortColumn === b
					) col = b
					else col = a
					*/
				} else {
					col = column
				}
			}
			//console.log(col)
			if (col) this.$store.dispatch(this.module + '/toggleColumn', col)
		},
		showDetail(title, message) {
			this.showSmallModal(AlertModal, { title, message })
		}
	}
}
</script>

<style lang='scss'>
.tableColumns {
	display: flex;
	box-sizing: border-box;
	background: $color-white;	
	border: 1px solid $color-neutral-shadow;
	border-bottom: none;
	font-size: $size-font-small;
	font-weight: bold;
	color: $color-primary-accent;

	> div {
		border-left: 1px solid $color-neutral-shadow;
		padding-left: $size-gutter * 2;
		padding-right: $size-gutter * 2;

		&:first-child { border-left: none; }

		&.selectable {
			cursor: pointer;

			&:hover { 
				color: $color-primary-accent;
				text-decoration: underline;
			}
		}

		&.selected {
			color: $color-primary-accent;
			text-decoration: underline;
		}

		span { flex: 1; }
		svg { flex: 0; }

		svg.caret {
			min-width: 12px;
			width: 12px;
			margin-left: $size-gutter;
			transition: transform 200ms linear;
			color: $color-primary-accent;
		}

		&.desc svg.caret {
			transform: rotate(180deg);
		}

		svg.detail {
			/*
			min-width: 12px + $size-gutter;
			width: 12px + $size-gutter;
			margin-left: auto;
			padding-left: $size-gutter;
			color: $color-primary-accent;
			cursor: pointer;
			*/

			color: $color-primary-accent;	
			background: #fff;
			height: 12px;
			border-radius: 50%;		
			position: absolute;
			width: 12px;
			top: 7px;
			right: -7px;
			transition: opacity 200ms linear;
			opacity: 0;
			cursor: pointer;
		}
		&:hover svg.detail { opacity: 1; }
	}

	> div > div {
		position: relative;
		display: flex;
		align-items: center;
		height: $size-control-height;
	}

	&.tilt > div {
		height: 145px;
		position: relative;
		overflow: hidden;

		&:after { 
			position: absolute; 
			top: 0;
			left: 0;
			width: 200%;
			height: 100px;
			background: linear-gradient(to top, #fff, 65%, $color-neutral);
			content: '';
			transform-origin: 0 100%;
			transform: rotate(-90deg);
		}

		> div {
			position: absolute;
			left: 0;
			bottom: -$size-gutter * 3;
			transform-origin: 0 0;
			transform: rotate(-90deg);
			white-space: nowrap
		}
	}
}
</style>