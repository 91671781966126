export const clickWithin = { 
    methods: {
        clickWithinStart(e) {
            this.clickWithinX = e.clientX
            this.clickWithinY = e.clientY
        },
        clickWithinEnd(e, fn) {
            if (
                Math.abs(this.clickWithinX - e.clientX) < 8 && 
                Math.abs(this.clickWithinY - e.clientY) < 8
            ) fn()
        }
    }
} 