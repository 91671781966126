<script>
import Lasso from '@/components/common/Lasso'

export default {
	name: 'RecycleLasso',
	extends: Lasso,
	props: [
		'itemHeight'
	],
	methods: {
		onClick: () => null,
		getKeys: function() {
			const containerBox = this.$el.getBoundingClientRect()
			const lassoBox = this.$refs.lasso.getBoundingClientRect()
			const selectTop = Math.floor((lassoBox.top - containerBox.top) / this.itemHeight)
			const selectBottom = Math.floor((lassoBox.bottom - containerBox.top) / this.itemHeight)
			const bools = this.selectableKeys.map((key, index) => index >= selectTop && index <= selectBottom)
			return bools.reduce((arr, bool, index) => {
				if (bool) arr.push(this.selectableKeys[index])
				return arr
			}, [])
		},
	}
}
</script>