<template>
<div 
	ref='lassoContainer' 
	v-on='!$store.state.main.mobile ? { pointerdown: onMousedown, click: e => clickWithinEnd(e, () => onClick(e)) } : {}'	
	class='lassoContainer'
	:class='{ lassoSelecting: selecting }'
>
	<div 
		v-if='selecting'
		ref='lasso'
		:style='{ top, left, width, height }' 
		class='lasso'
	/>
	<slot />
</div>
</template>

<script>
import { throttle, uniq } from 'lodash'
import { clickWithin } from '@/components/common/mixins/clickWithin'
import { Distance } from '@/utilities'
import { simpleState } from '@/store/helpers'

const getBox = el => el.getBoundingClientRect()
const intersects = (box1, box2) => !(
	box1.right < box2.left ||
	box1.left > box2.right ||
	box1.bottom < box2.top ||
	box1.top > box2.bottom
)

export default {
	name: 'Lasso',
	mixins: [clickWithin],
	props: [
		'selectableKeys',
		'selectableClass',
		'getSelected',
		'setSelected',
		'setSelecting'
	],
	data: () => ({
		selecting: false,
		els: [],
		scrollInterval: null,
		scrollEl: null,
		scrollY: 0,
		startX: 0,
		startY: 0,
		endX: 0,
		endY: 0,
		offsetX: 0,
		offsetY: 0,
		minX: 0,
		maxX: 0,
		minY: 0,
		maxY: 0
	}),
	computed: {
		top() {
			const top = Math.min(this.startY, (this.endY - this.scrollY)) - this.offsetY
			return Math.max(this.minY - this.offsetY, top) + 'px'
		},
		height() {
			let y
			if (this.endY > this.maxY) y = this.maxY
			else if (this.endY < this.minY + this.scrollY) y = this.minY
			else y = this.endY - this.scrollY
			return Math.abs(this.startY - y) + 'px'
		},
		left() {
			const left = Math.min(this.startX, this.endX) - this.offsetX
			return Math.max(this.minX - this.offsetX, left) + 'px'
		},
		width() {
			let x
			if (this.endX > this.maxX) x = this.maxX
			else if (this.endX < this.minX) x = this.minX
			else x = this.endX
			return Math.abs(this.startX - x) + 'px'
		}
	},
	methods: {
		lock: function() {
			simpleState.lassoSelecting = true
		},
		unlock: function() {
			simpleState.lassoSelecting = false
		},
		isLocked: function() {
			return !!simpleState.lassoSelecting
		},
		addListeners: function() {
			document.addEventListener('mousemove', this.onMousemove)
			document.addEventListener('mouseup', this.onMouseup)
		},
		removeListeners: function() {
			document.removeEventListener('mousemove', this.onMousemove)
			document.removeEventListener('mouseup', this.onMouseup)
		},
		setOffset: function() {
			const box = getBox(this.$el)
			this.offsetX = box.x
			this.offsetY = box.y
		},
		setContainerDimensions: function() {
			const box = getBox(this.$refs.lassoContainer)
			this.minX = box.left
			this.maxX = box.right
			this.minY = box.top
			this.maxY = box.bottom
			this.maxH = box.height
		},
		setSelectableEls: function() {
			this.els = [...this.$el.querySelectorAll('.' + this.selectableClass)]
		},
		getKeys: function() {
			const bools = this.els.map(el => intersects(getBox(this.$refs.lasso), getBox(el)))
			return bools.reduce((arr, bool, index) => {
				if (bool) arr.push(this.selectableKeys[index])
				return arr
			}, [])
		},
		onMousedown: function(e) {
			this.clickWithinStart(e)
			if (!!e.target.closest('.ctrlLasso') && !e.ctrlKey && !e.metaKey) return
			if (this.$store.state.main.mobile) return
			if (this.isLocked()) return
			this.lock()
			new Distance({
				onPull: () => {
					this.selecting = true
					this.scrollY = 0
					this.startX = this.endX = e.clientX
					this.startY = this.endY = e.clientY
					this.setOffset()
					this.setSelectableEls()
					this.setContainerDimensions()
					this.addListeners()
					if (this.scrollEl) this.startAutoScroll()
				},
				cx: () => this.unlock(),
				e
			})
		},
		onMousemove: function(e) {
			this.endX = e.clientX
			this.endY = e.clientY
			this.updateSelection()
		},
		onMouseup: function() {
			this.setSelected(uniq(this.getSelected().concat(this.getKeys())))
			this.setSelecting([])
			//clearInterval(this.scrollInterval)
			cancelAnimationFrame(this.scrollInterval)
			this.removeListeners()
			this.unlock()
			this.selecting = false
		},
		onClick: function(e) {
			e.stopPropagation()
			if (e.ctrlKey || e.metaKey || e.shiftKey) return
			this.setSelected([])
		},
		startAutoScroll: function() {
			const gutter = 50
			const accel = 8
			const box = getBox(this.scrollEl.parentNode)
			const scrollTop = this.scrollEl.scrollTop
			const getOffset = () => {
				if (this.endY > box.bottom - gutter) return (this.endY - box.bottom + gutter) / accel
				else if (this.endY < box.top + gutter) return -(box.top + gutter - this.endY) / accel
				else return 0
			}
			const atBottom = () => this.scrollEl.scrollTop >= this.maxH - this.scrollEl.clientHeight
			const repeat = () => {
				const offset = getOffset()
				this.scrollY = scrollTop - this.scrollEl.scrollTop
				this.scrollEl.scrollTop += offset
				if (offset < 0 || offset > 0 && !atBottom()) this.scrollEl.dispatchEvent(new Event('scroll'))
				this.updateSelection()
				this.scrollInterval = requestAnimationFrame(repeat)
			}
			this.scrollInterval = requestAnimationFrame(repeat)
		},
		updateSelection: throttle(function() {
			if (this.active && this.selecting) this.setSelecting(this.getKeys())
		}, 150, { leading: false, trailing: true })
	},
	watch: {
		selectableKeys: {
			handler() {
				this.$nextTick(() => {
					this.setContainerDimensions()
					this.setSelectableEls()
				})
			}
		}
	},
	mounted: function() {
		this.active = true
		this.scrollEl = this.$el.closest('.scrollContainer')
	},
	beforeDestroy() { this.active = false }
}
</script>

<style lang='scss'>
.lassoContainer {
	position: relative;
	overflow: hidden;

	.lasso {
		position: absolute;
		z-index: 9999;
		background: rgba($color-white, 0.85);
		border: 1px solid saturate(lighten($color-primary, 20%), 20%);
		box-shadow: inset 0 0 $size-control-height saturate(lighten($color-primary, 20%), 20%),
					0 0 7px rgba($color-black, 0.3);

		&:after {
			content: '';
			position: absolute;
			top: -50px;
			left: -50px;
			width: 100px;
			height: 100px;
		}
	}
}
</style>